import React from 'react'

import BrainBuildingBasics from '@components/programmatic_blocks/brain_building_basics'
import ExpertsList from '@components/programmatic_blocks/experts_list'
import Faqs from '@components/programmatic_blocks/faqs'
import ResearchArticles from '@components/programmatic_blocks/research_articles'

const BlockTypeMap = {
  BrainBuildingBasicsBlock: BrainBuildingBasics,
  ExpertsListBlock: ExpertsList,
  FaqBlock: Faqs,
  ResearchArticlesBlock: ResearchArticles,
}

class ProgrammaticBlock extends React.Component {
  render() {
    const { fields } = this.props
    const BlockType = BlockTypeMap[fields.type]
    if (fields.visible === false) return null

    if (BlockType) {
      return React.createElement(BlockType, {
        fields,
      })
    } else {
      return null
    }
  }
}

export default ProgrammaticBlock

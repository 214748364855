import React from 'react'
import classnames from 'classnames'

import { smoothScrollX } from '@util/smooth_scroll_to'
import styles from '@components/programmatic_blocks/card_fan.module.scss'

const cardSize = 250 + 20
const cardSizeLg = 333 + 20

class CardFan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      cursorClass: 'Left',
    }
  }

  handleClick = () => {
    if (this.container) {
      let pixels = window.innerWidth >= 768 ? cardSizeLg : cardSize
      if (this.state.cursorClass === 'Left') pixels = -1 * pixels
      smoothScrollX(this.container, pixels, 500)
    }
  }

  handleMouseMove = (e, f) => {
    const window_width = window.innerWidth
    const xPos = e.pageX
    let cursorClass = xPos - window_width / 2.0 > 0 ? 'Right' : 'Left'

    if (this.state.cursorClass !== cursorClass) {
      this.setState({ cursorClass: cursorClass })
    }
  }

  toggle = () => {
    this.setState({ collapsed: false })
  }
  handleMouseEnter = () => {
    this.setState({ collapsed: false })
  }

  render() {
    const children = this.props.children
    const numChildren = 5
    const displayedChildren = children.slice(0, numChildren)

    const cards = displayedChildren.map((child, i) => {
      return (
        <div key={i} className={`${styles.cardFan__card} ${styles[`cardFan__card--${i}`]}`}>
          {child}
        </div>
      )
    })

    const rootClasses = [styles.cardFan, styles[`cardFan--cursor${this.state.cursorClass}`]]
    if (this.state.collapsed) {
      rootClasses.push(styles['cardFan--collapsed'])
    } else {
      rootClasses.push(styles['cardFan--expanded'])
    }

    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleClick}
        className={classnames(rootClasses)}
      >
        <div
          className={styles.cardFan__container}
          onClick={this.toggle}
          ref={el => (this.container = el)}
        >
          <div className={styles.cardFan__cards}>{cards}</div>
        </div>
      </div>
    )
  }
}

export default CardFan
